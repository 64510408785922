/* button css */

.glow-on-hover {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    color: #fff;
    background: #2e4564;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 500;
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.glow-on-hover:active {
    color: #000;
    font-weight: 700;
}

.glow-on-hover:active:after {
    background: transparent;
}

.glow-on-hover:hover:before {
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--theme-color);
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}




/* goto screen button css */

.centerContain {
  width: 100%;
  height: 60px;
  /* position: absolute; */
}

.nextBtn {
  width: 100%;
  min-height: 50px;
  height: 100%;
  cursor: pointer;
  background: transparent;
  border-radius: 10px;
  position: relative;
  border: 1px solid #635ee7;
  outline: none;
  transition: 300ms ease-in-out;
}

.nextBtn svg {
  position: absolute;
  left: 0;
  top: 0;
  fill: none;
  border-radius: 10px;
  stroke: #fff;
  stroke-dasharray: 150 480;
  stroke-dashoffset: 150;
  transition: 300ms ease-in-out;
}

.nextBtn:hover {
  transition: 300ms ease-in-out;
  background: #635ee7;
}

.nextBtn:hover svg {
  stroke-dashoffset: -480;
}

.nextBtn span {
  color: white;
  font-size: 17px;
  align-items: center;
  font-weight: 500;
  justify-content: center;
}


/* pluse and minus buttons */

.noselect {
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
		-webkit-tap-highlight-color: transparent;
}

.volumeSettings button {
  width: 50px;
  height: 50px;
  border-radius: 70px;
  cursor: pointer;
  background: #18191e;
  margin: 10px;
  border: none;
  border-radius: 68px!important;
  box-shadow: -5px -5px 15px #18191e, 5px 5px 15px #18191e, inset 5px 5px 10px #18191e, inset -5px -5px 10px #18191e;
  color: #dbdbdb;
  font-size: 16px;
}

.volumeSettings button:active {
  box-shadow: -5px -5px 15px #101113, 5px 5px 15px #1a1b1f, inset 5px 5px 10px #000000, inset -5px -5px 10px #000000;
  font-size: 15px;
  transition: 50ms;
}

.volumeSettings button:focus {
	outline: none;
}

.red:hover {
	color: #f07171;
	text-shadow: 0px 0px 10px #f07171;
}

.noselect div{
  font-size: 32px;
  position: absolute;
  line-height: 0px;
  /* top: 22px; */
  /* left: 15px; */
  /* position: fixed; */
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.buttonIcon{
  height: 40px;
  width: 40px;
  padding: 8px;
}

.reOrderBtn{
  height: 100%;
  min-height: 50px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 10px;
  outline: none;
  border: none;
}
