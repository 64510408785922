input[type='color'] {
  padding: 0;
  width: 160%;
  height: 160%;
  border: none;
  margin: -25%;
}

.font-16 {
  font-size: 18px;
}


.cp_wrapper {
  overflow: hidden;
  width: 2rem;
  height: 2em;
  border-radius: 33px;
  /* optional formatting below here */
  /* border-radius: 50%; */
  box-shadow: 1px 1px 3px 0px grey;
  /* margin: 1em 0px; */
  border: 1px solid #fff;
}

.commanSmallFontHeading {
  font-size: 25px;
}

.commanUpperFontHeading {
  font-size: 21px;
}

.theme-text{
  color: var(--theme-color);
}

.inputField {
  border-radius: 10px;
  background: #000000;
  outline: none;
  border: none;
  color: #fff;
  font-size: 20px;
  height: 100%;
  min-height: 50px;
  width: 100%;
  padding: 0px 15px;
}



.righterBox {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.bgcolorText {
  display: flex;
  width: fit-content;
  padding: 0px 15px;
}

.stylesCotainer {
  background: #000000;
  /* min-height: 39px; */
  border-radius: 10px;
  padding: 15px;
}

.socialIconSection{
  display: grid;
  height: 100%;
  place-content: space-evenly;
}

.fullScreenIcon {
  width: 20px;
  padding-bottom: 2px;
}

.rightIcon{
  width: 13px;
  cursor: pointer;
}

.screenImage {
  /* position: absolute;
    z-index: 99; */
  /* height: 100%; */
  /* width: 100%; */
  /* border-radius: 11px; */
}

.fontSizeHandler {
  padding: 0px;
  border: 1px solid #fff;
  font-size: 25px;
  border-radius: 33px;
  width: 43px;
  height: 43px;
  margin: 0px 15px;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

#header-ads {
  overflow: hidden;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 90px;
}

.align-right {
  float: right;
}

/* Footr Css */
.FooterMainSection{
  background: #000;
}
.appName{
  font-size:45px;
  display: flex;
  align-items: center;
}
.footerDiscription{
  font-size:20px;
  font-weight: 300;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 10px;
}
.AppIcons{
  width: 140px;
}
.footerLinks{
  width: fit-content;
  cursor: default;
}
.footerAcnher{
  color: unset;
  cursor: pointer;
  color: var(--theme-color);
}
.footerAcnher:hover {
  color: unset;
}
.FooterCopyright{
  font-size: 19px;
  font-weight: 300;
  margin: 10px;
}
.web-border-bottom{
  border-bottom: 1px solid #dee2e6;
}

.videoSection{
  /* height: 331px; */
  overflow: hidden;
}

.blurLayer{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: grid;
  place-content: center;
}

.redirectSection, .redirectSection:hover{
  color: #000;
  text-decoration: none;
}

.blurEffect{
  filter: blur(4px);
}

.premiumText {
  color: #fff;
}

.tryNoewBtn{
  position: absolute;
  z-index: 999;
  border-radius: 10px;
  background-color: #f94f6c;
  width: 150px;
  border: none;
  font-size: 19px;
  height: 45px;
  left: 50%;
  transform: translate(-50%, -20px);
  bottom: 20px;
  color: #fff;
}

@media screen and (max-width: 740px) {
  .appName{
    font-size: 22px;
  }
  .footerDiscription{
    font-size: 15px;
  }
  .footerLinkSection{
    text-align: center;
  }
  .web-border-bottom{
    border-bottom: none;
  }.FooterCopyright{
    font-size: 12px;
  }
  .mobile-border-bottom{
    border-bottom: 1px solid #dee2e6;
  }
  .mobile-border-top{
    border-top: 1px solid #dee2e6;
  }
}
.adsbygoogle{
  display: block;
  text-align: center;
  max-height: 300px !important;
  /* width: 250px; */
}

@media screen and (max-width: 900px) and (orientation : landscape) {
  .righterBox {
    display: none !important;
  }
}
