
/* for the lazy */
.on
{
  display: none;
  transition: 1s ;
}

.off
{
  color: white;
  position: absolute;
  transform: translate(-50%,-50%);
  transition-delay: 1s left;
  top: 50%;
  left: 70%;
  font-size: 14px;
  font-family: Verdana, sans-serif;
}

.on
{
  color: white;
  position: absolute;
  transform: translate(-50%,-50%);
  top: 50%;
  transition: 400ms all ease-in-out 50ms;
  left: 33%;
  font-size: 14px;
  font-family: Verdana, sans-serif;
}

.toggleInput:checked+ .slider .on
{display: block;
  transition-delay: 1s ;

}

.toggleInput:checked + .slider .off
{display: none;
  transition: 1s ;}

/*--------- END --------*/

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;}


/* *,
*::before,
*::after {
  transition: 400ms all ease-in-out 50ms;
  box-sizing: border-box;
  backface-visibility: hidden;
} */

.togggleContainer *, *::before,
.togggleContainer *::after {
  transition: 400ms all ease-in-out 50ms;
  box-sizing: border-box;
  backface-visibility: hidden;
}



/* html,body{ text-align: center; font-family: 'Sanchez', serif;} */

.toggleInput {
  display: none;
}


/*Button is :CHECKED*/

.toggleInput:checked ~ .slider {
  background: rgba(73,168,68,1);
  box-shadow: 0 0 2px rgba(73,168,68,1);
}

.toggleInput:checked ~ div .toggleLable {
  left: 53px;
  transform: rotate(360deg);
}


/*shared*/

.slider,
.toggleLable {
  border-radius: 50px;
}


/*'un':checked state*/

.slider {
  height: 30px;
    width: 83px;
  background: #3a3e48;
  position: relative;
  
  box-shadow: 0 0 2px #3a3e48;
  
}

.toggleLable {
  height: 22px;
  width: 22px;
  background: rgba(255, 255, 255, 1);
  position: absolute;
  top: 4px;
  left: 7px;
  cursor: pointer;
}

.toggleLable::before {
  content: '';
  height: 18px;
  width: 3px;
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 1.5px);
  transform: rotate(45deg);
}

.toggleLable::after {
  content: '';
  height: 3px;
  width: 17px;
  position: absolute;
  top: calc(50% - 1.5px);
  left: calc(50% - 9px);
  transform: rotate(45deg);
}

.toggleLable::before,
.toggleLable::after{
  background: rgba(43,43,43,1);
  border-radius: 5px;
}

/* pesduo class on toggle */

.toggleInput:checked ~ div .toggleLable::before{
  height: 17px;
  top: calc(55% - 9px);
  left: calc(60% - 1.5px);
  background: rgba(73,168,68,1);
}
.toggleInput:checked ~ div .toggleLable::after{
  width: 9px;
  top: calc(95% - 8px);
  left: calc(22.5% - 4.5px);
  background: rgba(73,168,68,1);
}